<template>
  <div class="checkout">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-stepper alt-labels v-model="currentStep">
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1"> 確認購物車 </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="currentStep > 2" step="2"> 填寫資料 </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="currentStep > 3" step="3"> 完成訂購 </v-stepper-step>
            </v-stepper-header>
            <v-row>
              <v-col>
                <h4 v-if="currentStep == 1">訂單內容</h4>
                <h4 v-if="currentStep == 2">訂購人資訊</h4>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-stepper-items>
              <!-- 步驟一(訂單內容)--Start -->
              <v-form ref="checkoutForm1" v-model="formValid1" class="pa-0 ma-0">
                <v-stepper-content step="1" class="pa-0">
                  <v-row justify="center">
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">商品</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in cartList" :key="item.product_name" class="text-center">
                                  <td>
                                    <v-row justify="center" justify-md="start" align="center">
                                      <v-img max-width="100" max-height="100" :src="item.product_photo" class="ma-1">
                                      </v-img>
                                      <h3 style="white-space: pre-wrap">
                                        {{ item.product_name }}
                                      </h3>
                                    </v-row>
                                  </td>
                                  <td>
                                    <v-chip
                                      v-if="item.store_name == '重銷商城' || item.store_name == 'VIP商城'"
                                      label
                                      color="white"
                                    >
                                      <!-- <v-icon
                                        color="green"
                                        @click="DelItemCart(item)"
                                      >
                                        mdi-minus
                                      </v-icon> -->
                                      {{ item.amount }}
                                      <!-- <v-icon
                                        color="red"
                                        @click="AddItemCart(item.id)"
                                      >
                                        mdi-plus
                                      </v-icon> -->
                                      <!-- <v-icon @click="RemoveItemCart(item.id)">
                                        mdi-trash-can-outline
                                      </v-icon> -->
                                    </v-chip>
                                    <v-chip
                                      v-if="item.store_name == '加購區' || item.store_name == '贈品區'"
                                      label
                                      color="white"
                                    >
                                      {{ item.amount }}
                                    </v-chip>
                                  </td>
                                  <td>
                                    <h3 class="red--text">
                                      ${{
                                        item.store_name == '加購區' || item.store_name == '贈品區'
                                          ? item.price
                                          : item.price * item.amount
                                      }}
                                      <br />
                                      PV:{{ item.pv * item.amount }}
                                    </h3>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row v-if="!isCreateUrl">
                        <v-col>
                          <v-col class="pl-0">
                            <v-radio-group v-model="userCountry" row :rules="rules.required">
                              <v-radio label="郵寄" value="1"> </v-radio>
                              <v-radio label="現場取貨" value="2"> </v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-select
                            v-if="userCountry === '2'"
                            item-text="name"
                            :items="pickupCityName"
                            v-model="pickupCity"
                            placeholder="請選擇取貨地點"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <template v-if="userCountry === '1'">
                            <h4>寄貨資訊</h4>
                            <!-- <v-col class="pl-0">
                              <v-radio-group v-model="userCountry" row :rules="rules.required">
                                <v-radio label="台灣" value="Taiwan"> </v-radio>
                                <v-radio label="海外" value="Overseas"> </v-radio>
                              </v-radio-group>
                            </v-col> -->
                            <p>通訊地址</p>
                            <v-select
                              v-if="cityName"
                              item-text="name"
                              :items="cityName"
                              v-model="userCity"
                              placeholder="請選擇縣市"
                              filled
                              dense
                              :rules="rules.required"
                            >
                            </v-select>
                            <v-select
                              v-if="userCity"
                              item-text="name"
                              :items="cityArea"
                              v-model="userArea"
                              placeholder="請選擇區域"
                              filled
                              dense
                              :rules="rules.required"
                            >
                            </v-select>
                            <!-- <v-select
                              v-if="userArea"
                              :items="cityZip"
                              v-model="userZip"
                              placeholder="請選擇區碼"
                              filled
                              dense
                              :rules="rules.required"
                            >
                            </v-select> -->
                            <v-text-field
                              v-if="userArea"
                              v-model="cityZip"
                              placeholder="請選擇區碼"
                              filled
                              dense
                              readonly
                              :rules="rules.required"
                            >
                            </v-text-field>
                            <v-text-field
                              v-model="userAddress"
                              name="userAddress"
                              placeholder="請輸入您的地址"
                              class="pt-2"
                              :rules="rules.required"
                            >
                            </v-text-field>
                          </template>

                          <p>付款方式</p>
                          <v-select
                            v-model="userPayment"
                            :items="payment"
                            placeholder="請選擇付款方式"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <div v-if="userPayment == '信用卡付款'">
                            <p>分期期數</p>
                            <v-select
                              v-model="userTerm"
                              :items="cardTerm"
                              placeholder="請選擇分期期數"
                              filled
                              dense
                              :rules="rules.required"
                            >
                            </v-select>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-divider></v-divider>
                          <v-textarea
                            class="mt-5"
                            label="備註"
                            rows="1"
                            prepend-icon="mdi-comment"
                            v-model="remark"
                          ></v-textarea>
                          <h4>關於台灣本島訂購說明</h4>
                          <v-card outlined class="">
                            <p>
                              付款方式<br />
                              (1)信用卡付款<br />
                              (2)銀聯卡<br />
                              (3)ATM轉帳<br />
                              (4)銀行匯款<br />
                              (5)貨到付款(僅接受台灣本島地區)<br />
                              -<br />
                              ●選擇轉帳及匯款的客戶請於下訂單3天內完成繳費，並聯絡客服轉告轉帳帳戶後五碼及轉帳金額。<br />
                              ● 確認收到款項後，商品將於7天內寄出(不含假日)。<br />
                            </p>
                            <!-- 閱讀完整內容--Start -->
                            <v-row justify="center">
                              <v-dialog v-model="dialog" width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn block color="primary" v-bind="attrs" v-on="on" class="mt-2 mr-5">
                                    閱讀完整內容
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title>
                                    <span>關於台灣本島訂購說明</span>
                                  </v-card-title>
                                  <v-card-text>
                                    付款方式<br />
                                    (1)信用卡付款<br />
                                    (2)銀聯卡<br />
                                    (3)ATM轉帳<br />
                                    (4)銀行匯款<br />
                                    (5)貨到付款(僅接受台灣本島地區)<br />
                                    -<br />
                                    ●選擇轉帳及匯款的客戶請於下訂單3天內完成繳費，並聯絡客服轉告轉帳帳戶後五碼及轉帳金額。<br />
                                    ● 確認收到款項後，商品將於7天內寄出(不含假日)。<br /><br />

                                    寄送方式<br />
                                    宅配/新竹物流<br />
                                    海外/國際快遞 (順豐)<br />
                                    運費問題<br />
                                    ●宅配<br />
                                    新竹以北 100元<br />
                                    高雄以北150<br />
                                    屏東、花蓮 東部地區 200<br />
                                    偏鄉費用另計<br />
                                    ●宅配寄送若超過5公斤須拆單訂購。<br /><br />

                                    商品退換貨說明<br />
                                    【退貨】<br />
                                    欲辦理退貨，請務必保持包裝全新完整，保持商品、附件、包裝、廠商紙箱、明細、發票、所有隨付文件或資料之完整性，且以公平交易及多層次傳直銷管理辦法及事業手冊相關辦法辦理，否則恕不接受退貨。<br />
                                    ●請於購買7日內於服務時間聯繫客服以利辦理退貨。<br />
                                    ●本公司收到退回之完整商品及相關文件後，若經確認無誤將於14個工作天內處理退款/刷退之程序(含信用卡交易)。<br /><br />

                                    【換貨】<br />
                                    ●請於收到商品當下立即檢查商品是否正確及完整，若有出貨錯誤、與訂購內容不符、異常等狀況，請於24小時內以電話或傳真通知喜來麗，並於7日內完成辦理，請保留出貨明細，並協助拍下瑕疵部分，回傳客服，客服將於14日內協助換貨事宜。<br /><br />

                                    (請務必保持包裝全新完整，保持商品、附件、包裝、廠商紙箱、明細、發票、所有隨付文件或資料之完整性，否則恕不接受換貨)<br />
                                    -<br />
                                    ●退換貨客戶需自行負擔寄回本公司的運費。<br />
                                    ●因購物者所提供資料不完整無法即時聯繫，或因個人因素無法收件等導致損失，恕不接受退換貨。<br />
                                    ●喜來麗保有接受訂單與否之權利。<br /><br />

                                    取消訂單或更改訂單<br />
                                    ●訂單送出後即無法修改訂購內容，欲取消訂單或更改訂單內容，請於服務時間洽詢客服人員，喜來麗將協助您取消訂單或更改訂單。<br />
                                    ●加入購物車的商品不代表為您立即保留庫存數量，須完成結帳動作才能確保商品的保留及追加。<br /><br />

                                    訂單查詢<br />
                                    ●欲查詢訂單進度，請至訂單查詢內，輸入信箱與訂單編號，即可查看訂單明細。<br />
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-row>
                            <!-- 閱讀完整內容--End -->
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col class="mt-3">
                      <h4>訂單資訊</h4>
                      <v-card outlined>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>
                              運送地
                              <span v-if="userCity"> => [{{ userCity }}] </span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>小計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right"> NT.{{ orderAmount }} </v-col>
                        </v-row>
                        <v-row justify="space-between">
                          <v-col class="px-5 py-0">
                            <p>運費</p>
                          </v-col>
                          <v-col class="px-5 py-0 text-right"> NT.{{ orderShipping }} </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>合計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right">
                            NT.{{ orderTotal }}
                            <br />
                            PV:{{ orderPvTotal }}
                          </v-col>
                        </v-row>
                        <v-col>
                          <h3>營業處</h3>
                          <v-select
                            v-model="userServiceCenter"
                            :items="serviceCenterList"
                            item-text="Name"
                            item-value="ID"
                            placeholder="請選擇營業處"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <v-btn block color="primary" @click="nextStep()" v-if="!isCreateUrl"> 前往結帳 </v-btn>
                          <v-btn
                            block
                            color="primary"
                            v-if="shopcartUrl == '' && isCreateUrl"
                            @click="createUrl()"
                            :disabled="!userServiceCenter"
                          >
                            下一步-產生付款連結
                          </v-btn>
                          <v-btn block color="primary" @click="dialog3 = true" v-if="shopcartUrl != ''">
                            查看付款連結
                          </v-btn>
                          <!-- 建立連結彈出視窗--Start -->
                          <v-dialog v-model="dialog2" max-width="500px">
                            <v-card>
                              <v-card-title> 選擇VIP或建立新客戶 </v-card-title>
                              <v-card-text>
                                <v-btn :color="getColor('new')" dark @click="newCustomer = true" class="mb-3 mr-3">
                                  建立新客戶
                                </v-btn>
                                <v-btn :color="getColor('vip')" dark @click="newCustomer = false" class="mb-3">
                                  選擇VIP
                                </v-btn>
                                <v-select
                                  v-if="!newCustomer"
                                  v-model="customer"
                                  :items="customerList"
                                  item-text="customer_detail"
                                  item-value="customer_phone"
                                  placeholder="VIP清單"
                                  filled
                                  dense
                                ></v-select>
                                <v-text-field
                                  v-if="newCustomer"
                                  v-model="customer"
                                  label="新客戶（手機號碼）"
                                  outlined
                                ></v-text-field>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="primary" text @click="dialog2 = false"> 關閉 </v-btn>
                                <v-col class="text-right">
                                  <v-btn color="primary" @click="createUrl()" v-if="shopcartUrl == ''">
                                    產生付款連結
                                  </v-btn>
                                  <v-btn color="primary" @click="dialog3 = true" v-if="shopcartUrl != ''">
                                    查看付款連結
                                  </v-btn>
                                </v-col>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialog3" max-width="500px">
                            <v-card>
                              <v-card-title> 付款連結 </v-card-title>
                              <v-card-text>
                                <v-text-field v-model="shopcartUrl" label="付款連結" readonly></v-text-field>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="primary" text @click="dialog3 = false"> 關閉 </v-btn>
                                <v-col class="text-right">
                                  <v-btn color="primary" @click="copyUrl()"> 複製連結 </v-btn>
                                </v-col>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <!-- 建立連結彈出視窗--End -->
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-form>
              <!-- 步驟一(訂單內容)--Start -->

              <!-- 步驟二(填寫資料))--Start -->
              <v-form
                ref="checkoutForm2"
                v-model="formValid2"
                class="pa-0 ma-0"
                action="https://test.esafe.com.tw/Service/Etopm.aspx"
                method="POST"
                @submit.prevent="prepCreateOrderInfo($event)"
              >
                <v-stepper-content step="2" class="pa-0">
                  <v-row justify="center">
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col cols="12" md="6">
                          <label for="userName">姓名 *請輸入真實姓名</label>
                          <v-text-field
                            v-model="userName"
                            name="userName"
                            id="userName"
                            placeholder="請輸入真實姓名"
                            :rules="rules.name"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <label for="userPhoneNumber">電話</label>
                          <v-text-field
                            v-model="userPhoneNumber"
                            name="userPhoneNumber"
                            id="userPhoneNumber"
                            placeholder="請輸入您的電話"
                            :rules="rules.phoneNumber"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <label for="userMail">信箱 *如需更改信箱或是產品，請返回重新操作</label>
                          <v-text-field
                            v-model="userMail"
                            name="userMail"
                            id="userMail"
                            placeholder="請輸入您的信箱"
                            :rules="rules.emailRules"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <!-- <h3>營業處</h3>
                          <v-select
                            v-model="userServiceCenter"
                            :items="serviceCenterList"
                            item-text="Name"
                            item-value="ID"
                            placeholder="請選擇營業處"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select> -->
                          <h3>發票類型</h3>
                          <v-select
                            v-model="userInvoice"
                            :items="invoiceType"
                            placeholder="請選擇付款方式"
                            filled
                            dense
                            :rules="rules.required"
                          >
                          </v-select>
                          <label for="compilation">統編 *公司行號加入才可輸入統編</label>
                          <v-text-field
                            v-if="userInvoice == '公司-二聯式發票'"
                            v-model="compilation"
                            name="compilation"
                            id="compilation"
                            placeholder="輸入您的統編"
                            :rules="rules.taxId"
                          >
                          </v-text-field>
                          <v-checkbox v-model="agreePrivacy" :rules="rules.required">
                            <template v-slot:label>
                              <div>我已經閱讀並同意隱私權與條款</div>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col>
                      <h4>訂單資訊</h4>
                      <v-card outlined>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>
                              運送地
                              <span v-if="userCity"> => [{{ userCity }}] </span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>小計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right"> NT.{{ orderAmount }} </v-col>
                        </v-row>
                        <v-row justify="space-between">
                          <v-col class="px-5 py-0">
                            <p>運費</p>
                          </v-col>
                          <v-col class="px-5 py-0 text-right"> NT.{{ orderShipping }} </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="space-between">
                          <v-col class="px-5 pb-0">
                            <p>合計</p>
                          </v-col>
                          <v-col class="px-5 pb-0 text-right">
                            NT.{{ orderTotal }}
                            <br />
                            PV:{{ orderPvTotal }}
                          </v-col>
                        </v-row>
                        <v-col>
                          <input type="hidden" name="web" v-model="storeNo" />
                          <input type="hidden" name="MN" v-model="orderTotal" />
                          <input type="hidden" name="OrderInfo" v-model="orderInfo" />
                          <input type="hidden" name="TD" v-model="orderNo" />
                          <input type="hidden" name="sna" v-model="userName" />
                          <input type="hidden" name="sdt" v-model="userPhoneNumber" />
                          <input type="hidden" name="email" v-model="userMail" />
                          <input type="hidden" name="UserNo" v-model="$store.state.userInfo.member_id" />
                          <input
                            v-if="userPayment == '信用卡付款' || userTerm != ''"
                            type="hidden"
                            name="Term"
                            v-model="userTerm"
                          />
                          <input
                            v-if="userPayment == '信用卡付款' || userTerm != ''"
                            type="hidden"
                            name="Card_Type"
                            value="0"
                          />
                          <input type="hidden" name="ChkValue" v-model="ChkValue" />
                          <div v-for="(item, index) in $store.state.userCart" :key="item.id">
                            <input type="hidden" :name="`ProductName${index + 1}`" v-model="item.product_name" />
                            <input type="hidden" :name="`ProductPrice${index + 1}`" v-model="item.price" />
                            <input type="hidden" :name="`ProductQuantity${index + 1}`" v-model="item.amount" />
                          </div>
                          <input type="hidden" name="DueDate" v-model="DueDate" />
                          <v-btn v-if="!formValid2" block color="primary" @click="checkOut"> 前往結帳 </v-btn>
                          <v-btn v-if="formValid2" block color="primary" type="submit"> 前往結帳 </v-btn>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-form>
              <!-- 步驟二(填寫資料))--End -->
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import cityName from '@/assets/taiwan_districts.json';
import { mapGetters } from 'vuex';
import { checkoutPublicAPI } from '@/api/checkout/api';
import { userAPI } from '@/api/user/api';
import { shopcartAPI } from '@/api/shopcart/api';
import { showLoading, hideLoading } from '@/utils/loading';
import { productAPI } from '@/api/product/api';
import axios from 'axios';

export default {
  data() {
    return {
      remark: '',
      isLoading: false,
      formValid1: true,
      formValid2: true,
      rules: {
        required: [(value) => !!value || '必填欄位'],
        name: [(v) => !!v || '必填欄位', (v) => /[^0-9]/.test(v) || '請輸入正確的文字'],
        emailRules: [(v) => /.+@.+/.test(v) || '請輸入正確電子信箱'],
        phoneNumber: [(v) => (v && v.length >= 10) || '請輸入正確的手機號碼'],
        taxId: [(v) => (v && v.length >= 8) || '請輸入正確的統編號碼'],
      },
      currentStep: 1,
      dialog: false,
      userCountry: '',
      cityName,
      pickupCityName: ['桃園'],
      cityArea: '',
      cityZip: '',
      payment: ['信用卡付款', 'ATM轉帳'],
      cardTerm: ['0', '6', '12'],
      invoiceType: ['一般發票', '公司-二聯式發票'],
      compilation: '',
      serviceCenterList: [
        {
          ID: '0',
          Name: '無',
        },
      ],
      userCity: '',
      pickupCity: '',
      userArea: '',
      userZip: '',
      userAddress: '',
      userPayment: '',
      userName: '',
      userPhoneNumber: '',
      userMail: '',
      userInvoice: '',
      userServiceCenter: '',
      agreePrivacy: '',
      recipient_id: '',
      // 建立購物車連結
      isCreateUrl: false,
      dialog2: false,
      dialog3: false,
      newCustomer: false,
      customerList: [],
      customer: '',
      shopcartUrl: '',
      // 紅陽支付
      storeNo: '', // 商店代號
      orderNo: '', // 商家訂單編號
      orderInfo: '', // 交易內容
      DueDate: '', // 條碼付款繳費期限（當天 + 3 天)
      ChkValue: '', // 交易檢查碼
      userTerm: '', // 分期期數
    };
  },
  created() {
    console.log(this.cartList);
    // 確認購物車內容，如只有VIP商城之商品顯示建立連結
    let category = [];
    this.cartList.forEach((element) => {
      if (!category.find((item) => item == element.store_name)) {
        category.push(element.store_name);
      }
    });
    let categoryIndex = category.findIndex((item) => item === '重銷商城');
    if (categoryIndex == -1) {
      this.isCreateUrl = true;
    }

    // 產生繳費期限
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    let tmpDueDate = new Date();
    this.DueDate = tmpDueDate.addDays(3).toISOString().slice(0, 10).replaceAll('-', '');

    // 產生訂單號碼(會員編號 + YYYYMMDD + 隨機三碼流水號)
    this.orderNo =
      this.$store.state.userInfo.member_id +
      new Date().toISOString().slice(0, 10).replaceAll('-', '') +
      Math.floor(Date.now()).toString().substr(-3);

    // 載入營業處
    checkoutPublicAPI.get_service_center().then((res) => {
      let allowList = res.data.data.allowList.split(',');
      allowList.forEach((element, index) => {
        this.serviceCenterList[index + 1] = Object.assign(
          {},
          ...res.data.data.centerList.filter((item) => item.ID == element)
        );
      });
      this.$set(this.serviceCenterList, this.serviceCenterList);
    });
    // 向後端要求商品金額並更新到購物車中，確保消費者點擊完分期金額後又跳出導致金額落差
    this.$store.state.userCart.forEach((element) => {
      if (element.store_name == '重銷商城' || element.store_name == 'VIP商城') {
        productAPI.getOne(element.id).then((res) => {
          element.price = res.data.data.price;
        });
      }
    });
  },
  methods: {
    AddItemCart(parm) {
      this.$store.dispatch('doAddItemCart', parm);
    },
    DelItemCart(parm) {
      if (parm.amount - 1 == 0) {
        this.$store.dispatch('doRemoveItemCart', parm.id);
      } else {
        this.$store.dispatch('doDelItemCart', parm.id);
      }
    },
    RemoveItemCart(parm) {
      this.$store.dispatch('doRemoveItemCart', parm);
    },
    nextStep() {
      if (this.$store.state.userCart.length != 0) {
        if (this.$refs.checkoutForm1.validate()) {
          this.currentStep++;
        }
      } else {
        this.$Message.error('訂單沒有商品，請重新選購商品後再結帳');
      }
    },
    checkOut() {
      console.log(666);
      this.$refs.checkoutForm2.validate();
    },
    nextCreateUrl() {
      if (this.$store.state.userCart.length != 0) {
        this.dialog2 = true;
      } else {
        this.$Message.error('訂單沒有商品，請重新選購商品後再建立');
      }
    },
    async createUrl() {
      showLoading();
      await axios
        .post('http://web.hilary.com.tw:8085/getapi?type=CREATEURL', {
          creator_id: this.$store.state.userInfo.member_id,
          recipient_id: this.$route.params.recipient_id,
          recipient_phone: this.$route.params.recipient_phone,
          service_center_id: this.userServiceCenter,
          remark: this.remark,
        })
        .then((res) => {
          if (!res.data.success) {
            this.$Message.error(res.data.error);
            hideLoading();
            return;
          }
          this.shopcartUrl = `${document.URL}/${res.data.cart_id}?id=${this.$store.state.userInfo.member_id}`;
          let uuid = res.data.cart_id;
          this.$store.state.userCart.forEach(async (element) => {
            if (!element.price) element.price = 0;
            if (!element.set_id) element.set_id = '';
            if (!element.set_name) element.set_name = '';
            if (!element.product_photo) element.product_photo = '';
            await axios
              .post('http://web.hilary.com.tw:8085/getapi?type=CARD_INFO', {
                uuid: uuid,
                id: element.id,
                store_name: element.store_name,
                product_name: element.product_name,
                product_photo: element.product_photo,
                price: element.price,
                pv: element.pv,
                amount: element.amount,
                set_id: element.set_id,
                set_name: element.set_name,
              })
              .catch((e) => {
                this.$Message.error(e);
                hideLoading();
                return;
              });
          });
        })
        .catch((e) => {
          this.$Message.error(e);
          hideLoading();
          return;
        });
      hideLoading();
      this.dialog3 = true;
    },
    // createUrl() {
    //   // if (this.customer == "") {
    //   // this.dialog2 = false;
    //   this.dialog3 = true;
    //   // let customer = this.customerList.find(
    //   //   item => item.customer_phone == this.customer
    //   // );
    //   shopcartAPI
    //     .createShopCartUrl({
    //       creator_id: this.$store.state.userInfo.member_id,
    //       recipient_id: this.$route.params.recipient_id,
    //       recipient_phone: this.$route.params.recipient_phone,
    //       user_service_center: this.userServiceCenter,
    //     })
    //     .then((res) => {
    //       this.shopcartUrl = `${document.URL}/${res.data.data.cart_id}`;
    //       this.$store.state.userCart.forEach((element) => {
    //         shopcartAPI.createShopCartInfo(res.data.data.cart_id, {
    //           item_id: element.id,
    //           item_num: element.amount,
    //         });
    //       });
    //     });
    //   // } else {
    //   //   this.$Message.error("請選擇VIP清單或建立新客戶");
    //   // }
    // },
    getColor(target) {
      if (target == 'new') {
        // 建立新客戶未選取顏色
        if (!this.newCustomer) return 'blue-grey';
        // 建立新客戶已選取顏色
        if (this.newCustomer) return 'primary';
      }
      if (target == 'vip') {
        // 選擇VIP未選取顏色
        if (this.newCustomer) return 'blue-grey';
        // 選擇VIP已選取顏色
        if (!this.newCustomer) return 'primary';
      }
    },
    copyUrl() {
      navigator.clipboard
        .writeText(this.shopcartUrl)
        .then(() => {
          this.$Message.success('付款連結複製成功');
        })
        .catch((e) => {
          this.$Message.error('付款連結複製失敗');
        });
    },
    prepCreateOrderInfo(e) {
      if (!this.isLoading) {
        showLoading();
        this.isLoading = true;
        // 產生訂單號碼(會員編號 + YYYYMMDD + 隨機三碼流水號)
        this.orderNo =
          'N' +
          this.userPhoneNumber.slice(2) +
          new Date().toISOString().slice(0, 10).replaceAll('-', '') +
          Math.floor(Date.now()).toString().substr(-3);

        this.$store.state.userCart.forEach((element) => {
          console.log('set_id:', element.set_id);
          setTimeout(() => {
            axios
              .post('http://web.hilary.com.tw:8085/getapi?type=ORDER_INFO', {
                // checkoutPublicAPI.prep_creater_order_info({
                order_id: this.orderNo,
                order_total: this.orderTotal,
                order_pv_total: this.orderPvTotal,
                // member_id: this.recipient_id,
                member_id: this.recipient_id
                  ? this.recipient_id
                  : JSON.parse(localStorage.getItem('userInfo')).member_id,
                name: this.userName,
                phone: this.userPhoneNumber,
                email: this.userMail,
                address: this.cityZip + this.userCity + this.userArea + this.userAddress,
                creator_id: this.$store.state.userInfo.member_id,
                // service_center_id: this.service_center_id !== undefined ? parseInt(this.service_center_id) : 0,
                service_center_id: Number.isNaN(Number(this.service_center_id)) ? 0 : parseInt(this.service_center_id),
                item_id: element.id != '' ? element.id : '',
                item_name: element.product_name != '' ? element.product_name : '',
                // item_num: element.amount,
                item_total: element.price * element.amount,
                amount: element.amount,
                set_id: element.set_id !== undefined ? element.set_id : 0,
                set_name: element.set_name !== undefined ? element.set_name : '',
                esafe_id: this.storeNo,
                // esafe_id: 'S2010069026',
                esafe_check_value: this.ChkValue,
                payment_mode: this.userPayment,
                // invoiceType: this.invoiceType,
                card_term: this.userTerm, //分期
                invoiceType: this.userInvoice,
                compilation: this.compilation,
                cart_id: 0,
                od1gtype: this.userCountry, //取貨方式(1=郵寄,2=現場取貨)
                od1addr1: this.userCity, //縣市
                od1addr2: this.userArea, //區域
                zp1no: this.cityZip, //郵遞區號
                od1addr3: this.userAddress, //地址
                od1addr: this.pickupCity, //自取地址
              })
              .then((res) => console.log(res))
              .catch((e) => {
                console.log(e);
                this.$Message.error('建立訂單失敗');
                return;
              });
          }, 200);
        });
        setTimeout(() => {
          axios.post('http://web.hilary.com.tw:8085/getapi?type=EMAIL3', {
            member_id: this.recipient_id,
            name: this.userName,
            phone: this.userPhoneNumber,
            email: this.userMail,
            address: this.userZip + this.userCity + this.userArea + this.userAddress,
          });
          e.target.submit();
        }, 5000);
      }
    },
    // prepCreateOrderInfo(e) {
    //   if (!this.isLoading) {
    //     showLoading();
    //     this.isLoading = true;
    //     this.$store.state.userCart.forEach((element) => {
    //       checkoutPublicAPI.prep_creater_order_info({
    //         order_id: this.orderNo,
    //         order_total: this.orderTotal,
    //         order_pv_total: this.orderPvTotal,
    //         member_id: this.$store.state.userInfo.member_id,
    //         name: this.userName,
    //         phone: this.userPhoneNumber,
    //         email: this.userMail,
    //         address:
    //           this.userZip + this.userCity + this.userArea + this.userAddress,
    //         creator_id: "NULL",
    //         service_center_id: parseInt(this.userServiceCenter),
    //         item_id: element.id,
    //         item_name: element.product_name,
    //         item_num: element.amount,
    //         item_total: element.price * element.amount,
    //       });
    //     });
    //     setTimeout(() => {
    //       e.target.submit();
    //     }, 5000);
    //   }
    // },
  },
  computed: {
    ...mapGetters(['cartList']),
    orderAmount() {
      let all_money = 0;
      this.cartList.forEach((item) => {
        if (item.store_name == 'VIP商城' || item.store_name == '重銷商城') all_money += item.amount * item.price;
        else all_money += item.price;
      });
      return all_money;
    },
    orderShipping() {
      let all_money = 100;
      if (this.orderAmount == 0) {
        return (all_money = 0);
      } else {
        return (all_money = 0);
      }
    },
    orderTotal() {
      let all_money = 0;
      all_money += this.orderAmount + this.orderShipping;
      return all_money;
    },
    orderPvTotal() {
      let all_pv = 0;
      this.cartList.forEach((item) => {
        all_pv += item.amount * item.pv;
      });
      return all_pv;
    },
  },
  watch: {
    userCountry(newVal) {
      if (newVal === '1') {
        this.userCity = '';
        this.userArea = '';
        this.cityZip = '';
        this.userAddress = '';
      } else if (newVal === '2') {
        this.pickupCity = '';
        this.userPayment = '';
        this.userTerm = ''; //分期
      }
    },
    userCity(val) {
      this.cityArea = cityName.find((city) => city.name === this.userCity).districts;
      this.userZip = '';
    },
    userArea(val) {
      let index = this.cityArea.findIndex((city) => city.name == this.userArea);
      this.cityZip = this.cityArea[index].zip;
    },
    orderTotal() {
      if (this.orderTotal == 0) {
        this.$store.dispatch('doCleanUserCart');
      }
      if (this.userPayment !== '') {
        // checkoutPublicAPI
        //   .get_check_value({
        //     payment_mode: this.userPayment,
        //     order_total_price: this.orderTotal,
        //     card_term: this.userTerm == '0' ? '' : this.userTerm,
        //   })
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=GETVAL', {
            payment_mode: this.userPayment,
            order_total_price: this.orderTotal,
            card_term: this.userTerm == '0' ? '' : this.userTerm,
          })
          .then((res) => {
            // this.storeNo = res.data.data.storeNo;
            this.storeNo = res.data.storeNo;
            this.ChkValue = res.data.ChkValue;
            // this.ChkValue = res.data.data.ChkValue;
            this.orderInfo = '';
            this.$store.state.userCart.forEach((element) => {
              this.orderInfo += `${element.product_name} x ${element.amount} & `;
            });
            this.orderInfo = this.orderInfo.slice(0, -2);
          });
      }
    },
    userPayment() {
      // 向後端要求商品金額並更新到購物車中，確保消費者點擊完分期期數後又跳出導致金額落差
      this.userTerm = '';
      this.$store.state.userCart.forEach((element) => {
        if (element.store_name == '重銷商城' || element.store_name == 'VIP商城') {
          productAPI.getOne(element.id).then((res) => {
            element.price = res.data.data.price;
          });
        }
      });
      // checkoutPublicAPI
      //   .get_check_value({
      //     payment_mode: this.userPayment,
      //     order_total_price: this.orderTotal,
      //     card_term: this.userTerm == '0' ? '' : this.userTerm,
      //   })
      showLoading();
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETVAL', {
          payment_mode: this.userPayment,
          order_total_price: this.orderTotal,
          card_term: this.userTerm == '0' ? '' : this.userTerm,
        })
        .then((res) => {
          console.log(res.data.storeNo);
          // this.storeNo = res.data.data.storeNo;
          // this.storeNo = 'S2001149026';
          this.storeNo = res.data.storeNo;
          this.ChkValue = res.data.ChkValue;
          // this.ChkValue = res.data.data.ChkValue;
          this.orderInfo = '';
          this.$store.state.userCart.forEach((element) => {
            this.orderInfo += `${element.product_name} x ${element.amount} & `;
          });
          this.orderInfo = this.orderInfo.slice(0, -2);
          console.log(res.data.storeNo);
        });
    },
    userTerm() {
      // 定義分期數
      let term = '';
      switch (this.userTerm) {
        case '6':
          term = 'price_six';
          break;
        case '12':
          term = 'price_twelve';
          break;
        default:
          term = 'price';
          break;
      }
      // 向後端要求分期金額並更新購物車中的金額
      this.$store.state.userCart.forEach((element) => {
        if (element.store_name == '重銷商城' || element.store_name == 'VIP商城') {
          productAPI.getOne(element.id).then((res) => {
            console.log(res);
            element.price = res.data.data[term];
          });
        }
      });
      // checkoutPublicAPI
      //   .get_check_value({
      //     payment_mode: this.userPayment,
      //     order_total_price: this.orderTotal,
      //     card_term: this.userTerm == '0' ? '' : this.userTerm,
      //   })
      axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETVAL', {
          payment_mode: this.userPayment,
          order_total_price: this.orderTotal,
          card_term: this.userTerm == '0' ? '' : this.userTerm,
        })
        .then((res) => {
          // this.storeNo = res.data.data.storeNo;
          // this.storeNo = 'S2001149026';
          // this.ChkValue = res.data.data.ChkValue;
          this.storeNo = res.data.storeNo;
          this.ChkValue = res.data.ChkValue;
          this.orderInfo = '';
          this.$store.state.userCart.forEach((element) => {
            this.orderInfo += `${element.product_name} x ${element.amount} & `;
          });
          this.orderInfo = this.orderInfo.slice(0, -2);
        });
    },
    dialog2() {
      userAPI.getCustomerList().then((res) => {
        res.data.data.forEach((element) => {
          this.customerList.push(element);
        });
        this.customerList.forEach((element) => {
          element.customer_detail = `${element.customer_id} / ${element.customer_name}`;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.checkout {
  padding: 5%;
}
.checkout .v-stepper,
.checkout .v-stepper__header,
.checkout .v-stepper__content {
  box-shadow: unset !important;
}
.checkout .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: unset;
}
</style>
