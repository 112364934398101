<template>
  <div class="shoppingcart">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-row align="center" class="mt-2">
            <v-col cols="4" md="4" class="pb-0" v-if="totalPrice > 0">
              <v-select
                v-if="isCreateUrl"
                :items="customerList"
                item-text="customer_detail"
                item-value="customer_id"
                placeholder="選擇VIP"
                @input="vipSelector"
                filled
                dense
                v-model="customer"
              >
              </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" md="4" class="pb-0" v-if="totalPrice > 0">
              <v-text-field
                v-model.lazy="coupon"
                label="優惠碼"
                outlined
                dese
                @click="couponInput"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- 建立連結彈出視窗--Start -->
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title> 建立新客戶 </v-card-title>
              <v-card-text>
                <v-text-field v-model="newCustomerPhoneNum" label="新客戶（手機號碼）" outlined></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="dialog = false"> 關閉 </v-btn>
                <v-col class="text-right">
                  <v-btn color="primary" @click="createVIP()"> 儲存 </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- 建立連結彈出視窗--End -->
          <v-dialog v-model="couponDialog" max-width="500px">
            <v-card>
              <v-card-title> 請輸入您的優惠碼 </v-card-title>
              <v-card-text>
                <v-text-field v-model.lazy="coupon" label="優惠碼" outlined :readonly="isCouponConfirm"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="couponDialog = false"> 關閉 </v-btn>
                <v-col class="text-right">
                  <v-btn color="primary" @click="checkCoupon()" :disabled="coupon.length < 8 ? true : false">
                    確定
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card outlined class="mt-5" v-if="showcard == 1">
            <h3 class="text-center pa-5">購物車</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left pl-10">商品</th>
                    <th class="text-center">數量</th>
                    <th class="text-center">總價</th>
                    <th class="text-center">PV</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in cartList" :key="item.id" class="text-center">
                    <td>
                      <v-row justify="center" justify-md="start" align="center" style="height: 100%">
                        <v-img max-width="100" max-height="100" :src="item.product_photo"> </v-img>
                        <h3>
                          {{ item.product_name }}
                        </h3>
                      </v-row>
                    </td>
                    <td>
                      <v-chip label color="white">
                        <v-icon color="green" @click="DelItemCart(item)"> mdi-minus </v-icon>
                        {{ item.amount }}
                        <v-icon color="red" @click="AddItemCart(item.id, item)"> mdi-plus </v-icon>
                        <v-icon @click="RemoveItemCart(item.id, item)"> mdi-trash-can-outline </v-icon>
                      </v-chip>
                    </td>
                    <td>
                      <h3 class="red--text">
                        {{
                          item.store_name == '加購區' || item.store_name == '贈品區'
                            ? item.price
                            : item.price * item.amount
                        }}
                      </h3>
                    </td>
                    <td>
                      <h3 class="red--text">
                        {{ item.pv * item.amount }}
                      </h3>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row class="mb-0">
              <v-col class="pb-0">
                <h3 v-if="totalPrice != 0" class="text-right red--text mr-5">${{ totalPrice }} | PV:{{ totalPv }}</h3>
                <h3 v-if="totalPrice == 0" class="text-center">購物車空空的</h3>
              </v-col>
            </v-row>
            <v-row v-if="totalPrice > 0 && isCreateUrl" justify="end" class="mt-0 mr-1"> </v-row>

            <v-row>
              <v-col class="mx-1">
                <v-btn
                  v-if="totalPrice != 0 && customer == '' && storeResell && !isCoupon"
                  block
                  color="primary"
                  @click="checkOut()"
                >
                  下一步
                </v-btn>
                <v-btn
                  v-if="totalPrice != 0 && storeResell && shopcartUrl == '' && isCoupon"
                  block
                  color="primary"
                  @click="toPurchase"
                >
                  使用加購優惠
                </v-btn>
                <v-btn
                  v-if="totalPrice != 0 && isCreateUrl && customer != '' && shopcartUrl == '' && storeResell"
                  block
                  color="primary"
                  @click="createUrl()"
                >
                  建立連結
                </v-btn>

                <v-row justify="center" class="mb-2">
                  <v-col cols="6">
                    <v-btn
                      v-if="totalPrice != 0 && isCreateUrl && shopcartUrl == ''"
                      block
                      color="primary"
                      @click="toGiveaway()"
                    >
                      不使用加購優惠,下一步
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      v-if="totalPrice != 0 && isCreateUrl && shopcartUrl == ''"
                      block
                      color="primary"
                      @click="toPurchase"
                      :disabled="!isCoupon"
                    >
                      使用加購優惠
                    </v-btn>
                  </v-col>
                </v-row>
                <v-btn v-if="shopcartUrl != ''" block color="primary" @click="dialog3 = true"> 查看連結 </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <!-- 加購區 -->
          <v-card outlined v-if="showcard == 2 && isCoupon && totalPrice > 0">
            <h3 class="text-center pa-5 pb-0">加購區</h3>
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col class="text-left">
                  <span class="subtitle-2">
                    已達<b class="red--text">{{ totalPv }}PV({{ couponStar }})</b> 可選擇<b class="red--text"
                      >{{ addPurchaseOnly }}套</b
                    >
                  </span>
                </v-col>
                <v-col class="text-right pl-0" cols="5">
                  <v-select
                    :items="amoutItem"
                    item-text="name"
                    item-value="index"
                    @input="amountSelect"
                    label="選擇加購套數"
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left pl-10">商品</th>
                      <th class="text-center">套組細項</th>
                      <th class="text-center">數量</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in addPurchaseList" :key="item.id" class="text-center">
                      <td>
                        <v-row justify="center" justify-md="start" align="center">
                          <!-- <v-img
                            max-width="100"
                            max-height="100"
                            :src="item.product_photo"
                            class="ma-1"
                          >
                          </v-img> -->
                          <h3>
                            {{ item.set_name }}
                          </h3>
                        </v-row>
                      </td>
                      <td>
                        <div v-for="item2 in item.data3" :key="item2.id">
                          <h4>{{ item2.product_name }}</h4>
                        </div>
                      </td>
                      <td>
                        <v-chip label color="white">
                          <v-icon
                            color="green"
                            @click="item.amountShow == 0 ? null : item.amountShow-- && addPurchaseCount++"
                          >
                            mdi-minus
                          </v-icon>
                          {{ item.amountShow }}
                          <v-icon
                            color="red"
                            @click="
                              addPurchaseCount == 0
                                ? null
                                : item.amountShow++ && addPurchaseCount == 0
                                ? null
                                : addPurchaseCount--
                            "
                          >
                            mdi-plus
                          </v-icon>
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-col class="mx-1">
              <v-btn block color="primary" @click="addPurchaseNext()"> 下一步 </v-btn>
            </v-col>
          </v-card>
          <!-- 贈品區 -->
          <v-card outlined v-if="showcard == 3 && customer != ''">
            <h3 class="text-center pa-5">贈品區</h3>
            <v-card-text>
              <v-row>
                <v-col class="text-left">
                  <span class="subtitle-2">
                    {{ couponStar }}：尚可選擇<b class="red--text">{{ givewayCount }}套</b>贈品
                  </span>
                </v-col>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left pl-10">商品</th>
                      <th class="text-center">數量</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in giveawayList" :key="item.id" class="text-center">
                      <td>
                        <v-row justify="center" justify-md="start" align="center">
                          <v-img max-width="100" max-height="100" :src="item.product_photo"> </v-img>
                          <h3>
                            {{ item.product_name }}
                          </h3>
                        </v-row>
                      </td>
                      <td>
                        <v-chip label color="white">
                          <v-icon
                            color="green"
                            @click="item.amountShow == 0 ? null : item.amountShow-- && givewayCount++"
                          >
                            mdi-minus
                          </v-icon>
                          {{ item.amountShow }}
                          <v-icon
                            color="red"
                            @click="
                              givewayCount == 0 ? null : item.amountShow++ && givewayCount == 0 ? null : givewayCount--
                            "
                          >
                            mdi-plus
                          </v-icon>
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-col class="mx-1">
              <v-btn block color="primary" @click="giveawayNext()"> 下一步 </v-btn>
            </v-col>
          </v-card>
        </v-col>
        <!-- 複製連結 -->
        <v-dialog v-model="dialog3" max-width="500px">
          <v-card>
            <v-card-title> 付款連結 </v-card-title>
            <v-card-text>
              <v-text-field v-model="shopcartUrl" label="付款連結" readonly></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialog3 = false"> 關閉 </v-btn>
              <v-col class="text-right">
                <v-btn color="primary" @click="copyUrl()"> 複製連結 </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { shopcartAPI } from '@/api/shopcart/api';
import { userAPI } from '@/api/user/api';
import { productAPI } from '@/api/product/api';
import { showLoading, hideLoading } from '@/utils/loading';
import axios from 'axios';
import { thisTypeAnnotation } from '@babel/types';
export default {
  data() {
    return {
      storeResell: false,
      newCustomerPhoneNum: '',
      couponDialog: false,
      shopcartUrl: '',
      customerNum: '',
      dialog: false,
      showcard: 1,
      customerList: [{ customer_detail: '新增VIP', customer_id: '1' }],
      customer: '',
      dialog3: false,
      shopcartUrl: '',
      isCreateUrl: false,
      isCoupon: false,
      isCouponConfirm: false,
      coupon: '', //72945778
      couponStar: '',
      couponItem: null,
      couponInfo: [],
      addMoney: null,
      totalPV: null,
      purchaseSelect: 1,
      addPurchaseList: [],
      addPurchaseOnly: null,
      addPurchaseCount: 0,
      amoutItem: [],
      amoutItemIndex: 0,
      amount: 0,
      giveawayList: [],
      givewayOnly: null,
      givewayCount: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'Vip_Store' || to.name == 'Resell') {
      let category = [];
      this.cartList.forEach((element) => {
        if (!category.find((item) => item == element.store_name)) {
          category.push(element.store_name);
        }
      });
      // VIP商城判斷
      if (to.name == 'Vip_Store') {
        if (to.name == 'Vip_Store' && category.length == 1 && category[0] == 'VIP商城') {
          next();
        } else {
          if ((to.name == 'Vip_Store' && this.$store.state.userCart.length != 0) || category.length > 1) {
            this.$dialog['warning']({
              title: '清空購物車',
              text: '您即將前往購物商城，您要清空購物車嗎？（重銷商城與VIP商城之商品無法一同結帳）',
              persistent: true,
              actions: {
                true: { text: '清空' },
                false: { text: '取消' },
              },
            }).then((res) => {
              if (res) {
                this.$store.dispatch('doCleanUserCart');
                next();
              } else {
                next();
              }
            });
            next();
          }
        }
      }
      // 重銷商城判斷
      if (to.name == 'Resell') {
        if (to.name == 'Resell' && category.length == 1 && category[0] == '重銷商城') {
          next();
        } else {
          if ((to.name == 'Resell' && this.$store.state.userCart.length != 0) || category.length > 1) {
            this.$dialog['warning']({
              title: '清空購物車',
              text: '您即將前往購物商城，您要清空購物車嗎？（重銷商城與VIP商城之商品無法一同結帳）',
              persistent: true,
              actions: {
                true: { text: '清空' },
                false: { text: '取消' },
              },
            }).then((res) => {
              if (res) {
                this.$store.dispatch('doCleanUserCart');
                next();
              } else {
                next();
              }
            });
            next();
          }
        }
      }
    }
    next();
  },
  computed: {
    ...mapGetters(['cartList']),
    totalPrice() {
      let all_money = 0;
      this.cartList.forEach((item) => {
        all_money += item.amount * item.price;
      });
      return all_money;
    },
    totalPv() {
      let all_pv = 0;
      this.cartList.forEach((item) => {
        all_pv += item.amount * item.pv;
      });
      this.totalPV = all_pv;
      return all_pv;
    },
  },
  async created() {
    // 確認購物車內容，如只有VIP商城之商品顯示建立連結
    let category = [];
    this.cartList.forEach((element) => {
      if (!category.find((item) => item == element.store_name)) {
        category.push(element.store_name);
        if (category[0] === '重銷商城') {
          this.storeResell = true;
        }
      }
    });
    let categoryIndex = category.findIndex((item) => item === '重銷商城');
    if (categoryIndex == -1) {
      this.isCreateUrl = true;
    }
    // 取得顧客清單
    if (this.isCreateUrl) {
      showLoading();
      await axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETAMEM', {
          introducer_id: this.$store.state.userInfo.member_id,
        })
        .then((res) => {
          res.data.data.forEach((element) => {
            this.customerList.push(element);
          });
          this.customerList.forEach((element) => {
            element.customer_detail = `${element.customer_id} / ${element.customer_name}`;
          });
          this.customerList = this.customerList.filter((item) => {
            return item.is_active == '1';
          });
          this.customerList.unshift({
            customer_detail: '新增VIP',
            customer_id: '1',
          });
          hideLoading();
        })
        .catch((e) => {
          this.$message.error(e);
          hideLoading();
        });
      // userAPI.getCustomerList().then((res) => {
      //   res.data.data.forEach((element) => {
      //     this.customerList.push(element);
      //   });
      //   this.customerList.forEach((element) => {
      //     element.customer_detail = `${element.customer_id} / ${element.customer_name}`;
      //   });
      //   this.customerList.unshift({customer_detail:"新增VIP",customer_id:"1"});
      // });
    }
    // 如果有使用優惠碼重新進入到購物車進行清空
    this.$store.state.userCart
      .slice()
      .reverse()
      .forEach((element) => {
        if (element.store_name == '加購區' || element.store_name == '贈品區' || element.store_name == '優惠碼') {
          this.$store.dispatch('doRemoveItemCart', element);
        }
      });
  },
  methods: {
    couponInput() {
      if (this.customer != '') this.couponDialog = true;
      else if (this.storeResell) this.couponDialog = true;
      else return this.$Message.error(`請先選擇VIP客戶`);
    },
    createVIP() {
      let num = this.newCustomerPhoneNum.replace(/\s*/g, '');
      if (num.length <= 9) {
        return this.$Message.error(`請輸入完整的手機號碼`);
      } else {
        //新增VIP客戶API
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=NEWVIP', {
            introducer_id: this.$store.state.userInfo.member_id,
            introducer_name: this.$store.state.userInfo.name,
            big_name: this.newCustomerPhoneNum,
          })
          .then((res) => {
            if (res.status == 200 && res.data.success == true) {
              this.$Message.success('新增成功');
              let arr = {
                customer_detail: `${res.data.member_id}/ ${num}(新VIP)`,
                customer_id: res.data.member_id,
                customer_phone: num,
              };
              this.customerList.push(arr);
              this.customer = res.data.member_id;
            }
          })
          .catch((e) => {
            this.$message.error(e);
          });
        hideLoading();
        this.dialog = false;
      }
    },
    vipSelector(value) {
      if (value == '1') {
        this.dialog = true;
        this.customer = '';
        this.isCoupon = false;
        this.coupon = '';
      } else {
        this.customer = value;
      }
    },
    //取得贈品列表並顯示贈品區
    async toGiveaway(str) {
      if (!str) this.isCoupon = false;
      let id = 20000;
      if (this.customer != '') {
        showLoading();
        await axios
          .post('http://web.hilary.com.tw:8085/getapi?type=GETGIFT', {})
          .then((res) => {
            if (res.status != 200 && res.data.success != true) {
              this.$Message.error(res.data.error);
              return;
            }
            this.giveawayList = res.data.data;
            this.giveawayList = this.giveawayList.map((e) => {
              return {
                ...e,
                id: 0,
                amount: 0,
                amountShow: 0,
                pv: 0,
                price: 0,
                product_name: `(贈品)${e.set_name}`,
                set_id: e.set_id,
                set_name: e.set_name,
                store_name: '贈品區',
              };
            });
            this.giveawayList.forEach((element) => {
              element.id = id;
              id++;
            });
            if (str != 'addPurchaseNext') this.getGivewayStar();
            if (this.amoutItem != '') {
              this.givewayCount += this.amoutItem[this.amoutItemIndex].gift;
            }
            this.showcard = 3;
            hideLoading();
          })
          .catch((e) => {
            hideLoading();
            this.$Message.error(e);
            return;
          });
        // productAPI.getGivewayList().then((res) => {
        //     this.giveawayList = res.data.data;
        //     this.giveawayList = this.giveawayList.map(element => {
        //       return { ...element, amount: 0, amountShow: 0 };
        //     });
        //     this.showcard = 3;
        //   });
      } else {
        return this.$Message.error('請先選擇VIP客戶，才可進入下一步');
      }
    },
    //取有多少星&贈品數量
    async getGivewayStar() {
      showLoading();
      await axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETSTAR', {
          mtotal: this.totalPV,
        })
        .then((res) => {
          if (res.status == 200 && res.data.success == true) {
            if (res.data.mstar == '') this.couponStar = '0星';
            else this.couponStar = res.data.mstar;
            this.givewayOnly += res.data.mqty;
            this.givewayCount = res.data.mqty;
          } else {
            this.$Message.error(res.data.error);
          }
        })
        .catch((e) => {
          this.$Message.error(e);
        });
      hideLoading();
    },
    //取加購套組列表存入couponItem並顯示加購區
    async toPurchase() {
      showLoading();
      await axios
        .post('http://web.hilary.com.tw:8085/getapi?type=GETCODE2', {
          code_id: this.coupon,
          condition: this.totalPV,
        })
        .then((res) => {
          console.log(res);
          if (res.status != 200 && res.data.success != true) {
            this.$Message.error(res.data.error);
            return;
          }
          if (!res.data.data.length) {
            this.$Message.error('您選購的PV值不足，並不能使用加購優惠！');
            return;
          }
          this.couponItem = res.data.data;
          for (let i = 0; i < res.data.data.length; i++) {
            this.amoutItem.push({
              name: `加購套數：${res.data.data[i].num}（加購金額 :${res.data.data[i].price}）`,
              index: i,
              gift: res.data.data[i].gift,
              price: res.data.data[i].price,
              promo_name: res.data.data[i].promo_name,
            });
          }
          this.showcard = 2;
          this.givewayOnly = res.data.data[0].gift;
          this.givewayCount = res.data.data[0].gift;
          this.getGivewayStar();
        })
        .catch((e) => {
          this.$Message.error(e);
        });
      hideLoading();
    },
    //選擇加購方案並顯示套組列表
    amountSelect(index) {
      let id = 10000;
      let id2 = 0;

      this.amount = this.couponItem[index].price;
      this.addPurchaseList = this.couponItem[index].data2;
      this.addPurchaseOnly = this.couponItem[index].num;
      this.addPurchaseCount = this.couponItem[index].num;
      this.addPurchaseList = this.addPurchaseList.map((e) => {
        return { ...e, amountShow: 0, amount: 0, id: 0 };
      });
      this.addPurchaseList.forEach((element) => {
        element.id = id;
        element.data3.forEach((element2) => {
          element2.id = id2;
          id2++;
        });
        id++;
      });
      this.amoutItemIndex = index;
      // this.givewayCount = this.amoutItem[index].gift;
    },
    addPurchaseNext() {
      if (this.addPurchaseCount != 0) {
        return this.$Message.error('請選擇加購套組');
      }
      let productAdd = this.addPurchaseList.filter((item) => {
        return item.amountShow != 0;
      });
      productAdd = productAdd.map((e) => {
        return {
          ...e,
          product_name: `(加購)${e.set_name}`,
          set_id: e.set_id,
          set_name: e.set_name,
          pv: 0,
          price: 0,
          store_name: '加購區',
          amount: e.amountShow,
        };
      });
      //productAdd[0].price = this.amount;
      //productAdd.amount += productAdd.amountShow;
      productAdd.forEach((item) => {
        this.$store.dispatch('doAddItemToCart', item);
      });
      if (this.storeResell) {
        this.checkOut();
        return;
      }
      this.toGiveaway('addPurchaseNext');
    },
    giveawayNext() {
      if (this.givewayCount == 0) {
        let productAdd = this.giveawayList.filter((item) => {
          return item.amountShow != 0;
        });
        productAdd = productAdd.map((e) => {
          return {
            ...e,
            amount: e.amountShow,
          };
        });
        productAdd.forEach((item) => {
          this.$store.dispatch('doAddItemToCart', item);
        });
        this.checkOut();
      } else {
        return this.$Message.error(`請選擇${this.givewayOnly}套贈品`);
      }
    },
    AddItemCart(parm, param) {
      if (param.store_name == '加購區') {
        if (this.addPurchaseCount == 0) {
          return this.$Message.error(`僅限選擇${this.addPurchaseOnly}套`);
        }
        this.addPurchaseCount--;
      }
      if (param.store_name == '贈品區') {
        if (this.givewayCount == 0) {
          return this.$Message.error(`僅限選擇${this.givewayOnly}套`);
        }
        this.givewayCount--;
      }
      this.$store.dispatch('doAddItemCart', parm);
    },
    DelItemCart(parm) {
      if (parm.store_name == '加購區') {
        if (this.addPurchaseCount == this.addPurchaseOnly) {
          return null;
        }
        this.addPurchaseCount++;
      }
      if (parm.store_name == '贈品區') {
        if (this.givewayCount == this.addPurchaseOnly) {
          return null;
        }
        this.givewayCount++;
      }
      if (parm.amount - 1 == 0) {
        this.$store.dispatch('doRemoveItemCart', parm.id);
      } else {
        this.$store.dispatch('doDelItemCart', parm.id);
      }
    },
    RemoveItemCart(parm, param) {
      if (param.store_name == '加購區') {
        if (this.addPurchaseCount == this.addPurchaseOnly) {
          return null;
        }
        this.addPurchaseCount += param.amount;
      }
      if (param.store_name == '贈品區') {
        if (this.givewayCount == this.addPurchaseOnly) {
          return null;
        }
        this.givewayCount += param.amount;
      }
      this.$store.dispatch('doRemoveItemCart', parm);
    },
    addToCart(param) {
      if (param.store_name == '加購區') {
        if (this.addPurchaseCount == 0) {
          return this.$Message.error(`僅限選擇${this.addPurchaseOnly}套`);
        }
        this.addPurchaseCount--;
      }
      if (param.store_name == '贈品區') {
        if (this.givewayCount == 0) {
          return this.$Message.error(`僅限選擇${this.givewayOnly}套`);
        }
        this.givewayCount--;
      }
      param.amount += param.amountShow;
      this.$store.dispatch('doAddItemToCart', param);
    },
    checkOut() {
      if (this.isCoupon) {
        if (this.isCoupon && this.addPurchaseCount != 0) {
          return this.$Message.error(`加購或贈品套數尚未選擇齊全`);
        }
        let param = {
          id: this.couponInfo.id,
          store_name: '優惠碼',
          product_name: `${this.amoutItem[this.amoutItemIndex].promo_name}\n(優惠碼)${this.coupon}`,
          price: 0,
          pv: 0,
          product_photo: '',
          amountShow: 1,
        };
        param.price = this.amoutItem[this.amoutItemIndex].price;
        this.$store.dispatch('doAddItemToCart', param);
      }
      // 確認購物車內容，如同時有重銷商城及VIP商城之商品無法下一步
      let category = [];
      let storeCount = 0;
      this.cartList.forEach((element) => {
        if (!category.find((item) => item == element.store_name)) {
          category.push(element.store_name);
        }
      });
      category.forEach((element) => {
        element == '重銷商城' ? storeCount++ : null;
        element == 'VIP商城' ? storeCount++ : null;
      });
      if (storeCount > 1) {
        this.$Message.error('請將重銷商城、VIP商城產品分開訂單結帳。');
      } else {
        if (this.customer != '') {
          console.log('有傳');
          let customer = this.customerList.find((item) => item.customer_id == this.customer);
          this.$router.push({
            name: 'Checkout',
            params: {
              recipient_id: this.customer,
              recipient_phone: customer.customer_phone,
            },
          });
        } else {
          console.log('沒傳');
          this.$router.push('checkout');
        }
      }
    },
    // 畫面 demo 臨時
    checkCoupon() {
      this.couponDialog = false;
      if (this.coupon == '') {
        this.isCoupon = false;
        this.$store.state.userCart.forEach((element) => {
          if (element.store_name == '加購區' || element.store_name == '贈品區') {
            this.RemoveItemCart(element.id, element);
          }
        });
      } else {
        if (this.coupon.length >= 8 && !this.isCreateUrl) {
          // shopcartAPI.getPromo(this.coupon).then((res) => {
          //   for (let i = 1; i <= res.data.data.purchase; i++) {
          //     this.amoutItem.push(i);
          //   }
          //   this.isCoupon = true;
          //   this.couponItem = res.data.data;
          //   this.couponStar = res.data.data.star;
          //   this.addMoney = res.data.data.markup;
          //   this.addPurchaseOnly = res.data.data.purchase;
          //   this.givewayOnly = res.data.data.giveaway;
          //   this.givewayCount = res.data.data.giveaway;
          //   // 取得加購清單
          //   productAPI.getPurchaseList().then((res) => {
          //     this.addPurchaseList = res.data.data;
          //     this.addPurchaseList.forEach((element) => {
          //       element.amountShow = 1;
          //     });
          //   });
          //   // 取得贈品清單
          //   productAPI.getGivewayList().then((res) => {
          //     this.giveawayList = res.data.data;
          //     this.giveawayList.forEach((element) => {
          //       element.amountShow = 1;
          //     });
          //   });
          // });
          showLoading();
          axios
            .post('http://web.hilary.com.tw:8085/getapi?type=GETCODE', {
              code_id: this.coupon,
            })
            .then((res) => {
              if (res.data.success != true) {
                this.$Message.error(res.data.error);
                this.isCouponConfirm = false;
                hideLoading();
                return;
              }
              if (res.data.data[0].store_name != '1') {
                hideLoading();
                this.isCouponConfirm = false;
                return this.$Message.error('優惠碼無效，請確認是否重銷商城優惠碼！');
              }
              this.$Message.success('優惠碼有效');
              this.isCoupon = true;
              this.couponInfo = res.data.data[0];
              hideLoading();
            })
            .catch((e) => {
              this.$Message.error(e);
              hideLoading();
            });
        }
        if (this.coupon.length >= 8 && this.isCreateUrl && this.customer != '') {
          showLoading();
          axios
            .post('http://web.hilary.com.tw:8085/getapi?type=GETCODE', {
              code_id: this.coupon,
            })
            .then((res) => {
              if (res.data.success != true) {
                this.$Message.error(res.data.error);
                this.isCouponConfirm = false;
                hideLoading();
                return;
              }
              if (res.data.data[0].store_name != '2') {
                hideLoading();
                this.isCouponConfirm = false;
                return this.$Message.error('優惠碼無效，請確認是否VIP商城優惠碼！');
              }
              this.$Message.success('優惠碼有效');
              this.isCoupon = true;
              this.couponInfo = res.data.data[0];
              hideLoading();
            })
            .catch((e) => {
              this.$Message.error(e);
              hideLoading();
            });
          // shopcartAPI
          //   .getPromo(this.coupon, {
          //     member: this.customer,
          //   })
          //   .then((res) => {
          //     for(let i = 1; i <= res.data.data.purchase ; i++){
          //       this.amoutItem.push(i);
          //     }
          //     this.isCoupon = true;
          //     this.couponItem = res.data.data;
          //     this.couponStar = res.data.data.star;
          //     this.addMoney = res.data.data.markup;
          //     this.addPurchaseOnly = res.data.data.purchase;
          //     this.givewayOnly = res.data.data.giveaway;
          //     this.givewayCount = res.data.data.giveaway;
          //     // 取得加購清單
          //     productAPI.getPurchaseList().then((res) => {
          //       this.addPurchaseList = res.data.data;
          //       this.addPurchaseList = this.addPurchaseList.map(element => {
          //         return { ...element, amount: 0, amountShow: 0 };
          //       });
          //     });
          //     // 取得贈品清單
          //     productAPI.getGivewayList().then((res) => {
          //       this.giveawayList = res.data.data;
          //       this.giveawayList = this.giveawayList.map(element => {
          //         return { ...element, amount: 0, amountShow: 0 };
          //       });
          //     });
          //   });
        }
      }
    },
    copyUrl() {
      navigator.clipboard
        .writeText(this.shopcartUrl)
        .then(() => {
          this.$Message.success('付款連結複製成功');
        })
        .catch((e) => {
          this.$Message.error('付款連結複製失敗');
        });
    },
    createUrl() {
      if (this.isCoupon) {
        if ((this.isCoupon && this.addPurchaseCount != 0) || this.givewayCount != 0) {
          return this.$Message.error(`加購或贈品套數尚未選擇齊全`);
        }
        let param = {
          id: this.couponItem.id,
          store_name: '優惠碼',
          product_name: `(優惠碼)${this.coupon}`,
          price: this.addMoney,
          pv: 0,
          product_photo: `https://fakeimg.pl/490x686/?text=${this.coupon}`,
          amountShow: 1,
        };
        this.$store.dispatch('doAddItemToCart', param);
      }
      // 確認購物車內容，如同時有重銷商城及VIP商城之商品無法下一步
      let category = [];
      let storeCount = 0;
      this.cartList.forEach((element) => {
        if (!category.find((item) => item == element.store_name)) {
          category.push(element.store_name);
        }
      });
      category.forEach((element) => {
        element == '重銷商城' ? storeCount++ : null;
        element == 'VIP商城' ? storeCount++ : null;
      });
      if (storeCount > 1) {
        this.$Message.error('請將重銷商城、VIP商城產品分開訂單結帳。');
      } else {
        this.dialog3 = true;
        if (this.customer != '') {
          let customer = this.customerList.find((item) => item.customer_id == this.customer);
          shopcartAPI
            .createShopCartUrl({
              creator_id: this.$store.state.userInfo.member_id,
              recipient_id: this.customer,
              recipient_phone: customer.customer_phone,
            })
            .then((res) => {
              this.shopcartUrl = `${window.location.origin}/checkout/${res.data.data.cart_id}`;
              this.$store.state.userCart.forEach((element) => {
                shopcartAPI.createShopCartInfo(res.data.data.cart_id, {
                  item_id: element.id,
                  item_num: element.amount,
                });
              });
            });
        }
      }
      //
    },
  },
};
</script>
<style scoped>
.product-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
}

.product-image v-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
